import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RpmTableHeader = ({ headers, onSorting }) => {
    const [sortingField, setSortingField] = useState('');
    const [sortingOrder, setSortingOrder] = useState('asc');

    const onSortingChange = field => {
        const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc';

        setSortingField(field);
        setSortingOrder(order);
        onSorting(field, order);
    };

    return (
        <thead>
            <tr>
                {headers.map(({ name, field, sortable, align }) => (
                    <th
                        key={name}
                        onClick={() => (sortable ? onSortingChange(field) : null)}
                        style={{ textAlign: align, borderTop: 'none' }}
                    >
                        {name}

                        {sortingField &&
                            sortingField === field &&
                            (sortingOrder === 'asc' ? <span> ▲</span> : <span> ▼</span>)}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export default RpmTableHeader;

import React, { Component } from 'react';
import axios from '../api';
import Swal from 'sweetalert2';
import {Link} from "react-router-dom";

const req = {
  color: 'red'
}
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      repassword: "",
      token: this.props.match.params.resetToken,
      error: null
    }
    this.submitResetPassword = this.submitResetPassword.bind(this);
  }

  submitResetPassword() {
    if(!this.state.password || this.state.password === null) {
      this.setState({
        error: 'Password should not be null'
      });
      return;
    }
    if(!this.state.repassword || this.state.repassword === null) {
      this.setState({
        error: 'Re-Enter Password should not be null'
      });
      return;
    }
    if(!this.state.password === this.state.repassword) {
      this.setState({
        error: 'Password i'
      });
      return;
    }
    axios.post("/benefit/reset-password", {password: this.state.password, token: this.state.token}).then(res => {
      this.setState({
        password: "",
        repassword: ""
      });
      Swal.fire(
        'Success',
        `Password is updated successfully`,
        'success'
      )
    }, err => {
      this.setState({
        error: err.response.data || 'Unable to process, Try Again Later'
      });
    });
  }

  render() {
    let error;
    if(this.state.error) {
      error = <p style={{fontSize:'15px',...req}} className="text-center">{this.state.error}</p>
    }
    return (
      <div className="row justify-content-center">
        <div className="col-4 signInContainer">
          <h4 className="text-center" style={{marginBottom:'20px'}}>Reset Password</h4>
          {error}
          <div className="form-group">
            <label>Password <span style={req}>*</span></label>
            <input type="password" className="form-control" value={this.state.password} onChange={(event) => {
              this.setState({
                password: event.target.value
              })
            }} onKeyDown={(event) => {
              if (event.keyCode === 13) {
                event.preventDefault();
                this.submitResetPassword();
              }
              }} placeholder="Password"/>
          </div>
          <div className="form-group">
            <label>Re-Enter Password <span style={req}>*</span></label>
            <input type="password" className="form-control" value={this.state.repassword} onChange={(event) => {
              this.setState({
                repassword: event.target.value
              })
            }} onKeyDown={(event) => {
              if (event.keyCode === 13) {
                event.preventDefault();
                this.submitResetPassword();
              }
              }} placeholder="Password"/>
          </div>
          <div className="signInContainer-buttons">
            <Link className="careButton" to="/login" value="Go" >Go to Login</Link>
            <div className="careButton" disabled="" id="signIn" value="Go" onClick={(event) => {
                event.preventDefault();
                this.submitResetPassword();
              }}>Reset Password</div>
          </div>
        </div>
      </div>
    );
  }
};

export default ResetPassword;

import React, { Component } from 'react';
import logo from '../assets/img/logo.svg';

const listStyle = { listStyle: 'none', padding: '0px', margin: '0px', float: 'right' };
const listLiStyle = {
  color: '#fff',
  float: 'left',
  padding: '10px'
};

class Header extends Component {
  constructor(props) {
    super();
    this.state = {
      profile: JSON.parse(localStorage.getItem('profile'))
    }
  }
  logout(e) {
    e.preventDefault();
    localStorage.clear();
    window.location.href = "/";
  }
  render() {
    let menu;
    if(this.state.profile) {
      let protectedList;
      if(this.state.profile.role && this.state.profile.role === "admin") {
        protectedList = <li style={listLiStyle}><a style={{color:'inherit', letterSpacing: '0.5px', textDecoration: 'none'}} href="/users">Users</a></li>
      }
      menu = <ul style={listStyle}>
      <li style={listLiStyle}><a style={{color:'inherit', letterSpacing: '0.5px', textDecoration: 'none'}} href="/">Benefits</a></li>
        {protectedList}
      <li style={listLiStyle}><a style={{color:'inherit', letterSpacing: '0.5px', textDecoration: 'none'}} href="/profile">Profile</a></li>
      <li style={listLiStyle}><a style={{color:'inherit', letterSpacing: '0.5px', textDecoration: 'none'}} href="#" onClick={this.logout}>Logout</a></li>
    </ul>
    }
    return (
      <div id="banner" data-original-title="" title="">
        <a href="/" data-original-title="" title="">
          <img id="carex-logo" src={logo} data-original-title="" title="" />
        </a>
        {menu}
      </div>
    );
  }
}

export default Header;

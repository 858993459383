import React, { Component } from 'react';
import Loading from '../assets/img/wait.gif';
import moment from 'moment';
import instance from '../api';
import RpmTableHeader from '../components/RpmTableHeader'
import Swal from 'sweetalert2';

const errorStyle = {
  fontSize: '10px',
  color: 'red',
  display: 'block'
};

const headers = [
  {
    name: 'Name',
    field: 'name',
    sortable: false,
    align: 'left'
  },
  {
    name: 'Email Address',
    field: 'email',
    sortable: false,
    align: 'left'
  },
  {
    name: 'Mobile',
    field: 'mobile',
    sortable: false,
    align: 'left'
  },
  {
    name: 'Role',
    field: 'role',
    sortable: false,
    align: 'left'
  },
  {
    name: 'Status',
    field: 'isActive',
    sortable: false,
    align: 'left'
  },
  {
    name: 'Created On',
    field: 'createdDate',
    sortable: false,
    align: 'left'
  }
];

const userFields = ["name","phone","role","password","email"];

class Users extends Component {
  constructor(props) {
    super();
    this.state = {
      users: [],
      payload: {
        name: null,
        phone: null,
        role: "edit",
        isActive: 1,
        password: null,
        email: null
      },
      loading: true,
      visibleForm: false
    }
    this.createUser = this.createUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.generateTable = this.generateTable.bind(this);
    this.generateForm = this.generateForm.bind(this);
    this.resetPayload = this.resetPayload.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.validateData = this.validateData.bind(this);
    this.statusLabel = this.statusLabel.bind(this);
  }

  createUser(e) {
    e.preventDefault();
    if(this.validateData()) {
      instance.post("/benefit/user", this.state.payload).then(res => {
        this.setState({
          visibleForm: false
        });
        Swal.fire(
          'Success',
          'User created successfully',
          'success'
        );
        this.getDataList();
      }, err => {
        Swal.fire(
          'Failed',
          'User creation failed',
          'danger'
        );
      });
    }
  }

  updateUser(e) {
    e.preventDefault();
    if(this.validateData()) {
      instance.put(`/benefit/user/${this.state.payload.id}`, this.state.payload).then(res => {
        this.setState({
          visibleForm: false
        });
        Swal.fire(
          'Success',
          'User updated successfully',
          'success'
        );
        this.getDataList();
      }, err => {
        Swal.fire(
          'Failed',
          'User updated Failed',
          'danger'
        );
      });
    }
  }

  validateData() {
    let proceed = true;
    let error = {};
    userFields.forEach(field => {
      if(this.state.payload.id && field==="password") {
      } else if(!this.state.payload[field] || this.state.payload[field] === "") {
        error[field] = `${field} is required`;
        proceed = false;
      }
    });
    console.log(error);
    this.setState({
      error: error
    });
    return proceed;
  }

  getDataList() {
    instance.get("/benefit/user").then(res => {
      this.setState({
        users: res.data,
        loading: false
      });
    }, err => {
      console.log('Unable to get users....');
    });
  }

  resetPayload() {
    this.setState({
      payload: {
        name: null,
        phone: null,
        role: "view",
        isActive: 1,
        password: null,
        email: null
      },
      error: {},
      visibleForm: true
    });
  }

  componentDidMount() {
    this.getDataList();
  }

  statusLabel(status) {
    if(status) {
      return <span className="text-success">Active</span>;
    } else {
      return <span className="text-danger">InActive</span>;
    }
  }

  generateTable(state) {
    if(state.loading) {
      return <tbody>
        <tr>
          <td colSpan="7" className="text-center">
            <img src={Loading} style={{width: '250px'}}/>
          </td>
        </tr>
      </tbody>
    } else if(this.state.users.length===0) {
      return <tbody>
        <tr>
          <td colSpan="7" className="text-center">
            <p className="text-center">No entries found</p>
          </td>
        </tr>
      </tbody>
    } else {
      return <tbody>
        {this.state.users.map(item => (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.phone}</td>
            <td>{item.role}</td>
            <td>{this.statusLabel(item.isActive)}</td>
            <td>{moment(item.createdDate).utc().format('MMM,DD,YYYY h:mm a')}</td>
            <td>
              <i className="fa fa-pencil" onClick={() => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    ...item
                  },
                  error: {},
                  visibleForm: true
                }))
              }}></i>
            </td>
          </tr>
        ))}
      </tbody>
    }
  }

  getErrorMessage(field) {
    return this.state.error && this.state.error[field]?<span style={errorStyle}>{this.state.error[field]}</span>:'';
  }

  generateForm() {
    let saveBtn = <div className="careButton" disabled="" id="signIn" value="Go" onClick={this.createUser}>Create</div>
    let header = <h6>Create User</h6>;
    let password;
    if(this.state.payload.id) {
      header = <h6>Edit User</h6>;
      saveBtn = <div className="careButton" disabled="" id="signIn" value="Go" onClick={this.updateUser}>Update</div>
    } else {
      password = <div className="col-3">
        <div className="form-group">
          <label>Password</label><br />
          <input type="password" className="form-control" value={this.state.payload.password} onChange={(event) => {
            this.setState(prevState => ({
              payload: {
                ...prevState.payload,
                password: event.target.value
              }
            }));
          }} placeholder="Enter password" />
          {this.getErrorMessage('password')}
        </div>
      </div>;
    }
    if(this.state.visibleForm) {
      return <div className="customContainer" style={{ marginBottom: '20px' }}>
        {header}
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label>Name</label><br />
              <input type="text" className="form-control" value={this.state.payload.name} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    name: event.target.value
                  }
                }));
              }} placeholder="Enter name" />
              {this.getErrorMessage('name')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Email</label><br />
              <input type="email" className="form-control" value={this.state.payload.email} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    email: event.target.value
                  }
                }));
              }} placeholder="Email Address" />
              {this.getErrorMessage('email')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Mobile</label><br />
              <input type="number" className="form-control" value={this.state.payload.phone} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    phone: event.target.value
                  }
                }));
              }} placeholder="Mobile Number" />
              {this.getErrorMessage('phone')}
            </div>
          </div>
          {password}
          <div className="col-3">
            <div className="form-group">
              <label>User Role</label><br />
              <select className="form-control" value={this.state.payload.role} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    role: event.target.value
                  }
                }));
              }}>
                <option value="view">View</option>
                <option value="edit">Edit</option>
                <option value="admin">Admin</option>
              </select>
              {this.getErrorMessage('role')}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Status</label><br />
              <select className="form-control" value={this.state.payload.isActive} onChange={(event) => {
                this.setState(prevState => ({
                  payload: {
                    ...prevState.payload,
                    isActive: event.target.value
                  }
                }));
              }}>
                <option value="1">Active</option>
                <option value="0">InActive</option>
              </select>
            </div>
          </div>
          <div className="col-12 text-left">
            <div className="signInContainer-buttons" style={{ justifyContent: 'left' }}>
              {saveBtn}
              <div className="careButton" disabled="" id="signIn" value="Go" onClick={() => {
                this.setState({
                  visibleForm: false
                })
              }}>Cancel</div>
            </div>
          </div>
        </div>
      </div>
    } else {
      return;
    }
  }

  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-12">
          {this.generateForm()}
        </div>
        <div className="col-12">
          <div className="customContainer" style={{ marginBottom: '20px' }}>
            <h5>Users</h5> <button className="btn btn-outline-primary btn-sm f-right" onClick={this.resetPayload}>+ Add</button>
            <table className="table">
              <RpmTableHeader headers={headers}></RpmTableHeader>
              {this.generateTable(this.state)}
            </table>
          </div>
        </div>
      </div>
    );
  }
};

export default Users;
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import Home from './pages/Home'
import Header from './pages/Header'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Users from './pages/Users'
import Profile from './pages/Profile'


const GuardedRoute = ({ component: Component, auth, ...rest }) => (
  <Route {...rest} render={(props) => (
      auth === true
          ? <Component {...props} />
          : <Redirect to='/login' />
  )} />
)

class App extends Component {
  constructor(props) {
    super();
    this.state = {
      authenticated: localStorage.getItem('profile')?true:false
    }
  }

  render() {
    return (
      <div className="App">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col" style={{padding: 0}}>
              <Header></Header>
            </div>
          </div>
          <div className="row" style={{marginTop:'30px'}}>
            <div className="col">
              <Router>
                <Switch>
                  <Route exact path="/login">
                    <Login />
                  </Route>
                  <Route exact path="/forgot-password">
                    <ForgotPassword />
                  </Route>
                  <Route exact path="/reset-password/:resetToken" component={ResetPassword}/>
                  <GuardedRoute path='/users' component={Users} auth={this.state.authenticated} />
                  <GuardedRoute path='/profile' component={Profile} auth={this.state.authenticated} />
                  <GuardedRoute path='/' component={Home} auth={this.state.authenticated} />
                </Switch>
              </Router>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;

import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP__API_URL || "http://localhost:3000"
});
instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('Token') || null;
    if(token) {
        config.headers.Authorization =  "Bearer "+localStorage.getItem('Token') || null;
    }
    return config;
});

instance.interceptors.response.use(null, (error) => {
    if(error.config && error.response && (error.response.status === 401 || error.response.status === 403)) {
        localStorage.clear();
        window.location.href = "/login";
    }
    return Promise.reject(error);
});

export default instance;

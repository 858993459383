import React, { Component } from 'react';
import axios from '../api';
import {Link} from "react-router-dom";
const req = {
  color: 'red'
}
class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      password: "",
      error: null
    }
    this.submitLogin = this.submitLogin.bind(this);
  }

  submitLogin() {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!this.state.email || this.state.email === null || !re.test(String(this.state.email).toLowerCase())) {
      this.setState({
        error: 'Enter valid email address'
      });
      return;
    }
    if(!this.state.password || this.state.password === null) {
      this.setState({
        error: 'Password should not be null'
      });
      return;
    }
    axios.post("/benefit/login", {email: this.state.email, password: this.state.password}).then(res => {
      localStorage.setItem('Token', res.data.token);
      localStorage.setItem('profile', JSON.stringify(res.data.user));
      window.location.href = "/";
    }, err => {
      this.setState({
        error: 'Invalid Credentials'
      });
    });
  }

  render() {
    let error;
    if(this.state.error) {
      error = <p style={{fontSize:'15px',...req}} className="text-center">{this.state.error}</p>
    }
    return (
      <div className="row justify-content-center">
        <div className="col-4 signInContainer">
          <h4 className="text-center" style={{marginBottom:'20px'}}>SignIn</h4>
          {error}
          <div className="form-group">
            <label>Email address <span style={req}>*</span></label>
            <input type="email" className="form-control" value={this.state.email} onChange={(event) => {
              this.setState({
                email: event.target.value
              })
            }} onKeyDown={(event) => {
              if (event.keyCode === 13) {
                event.preventDefault();
                this.submitLogin();
              }
              }} placeholder="Enter email"/>
          </div>
          <div className="form-group">
            <label>Password <span style={req}>*</span></label>
            <input type="password" className="form-control" value={this.state.password} onChange={(event) => {
              this.setState({
                password: event.target.value
              })
            }} onKeyDown={(event) => {
              if (event.keyCode === 13) {
                event.preventDefault();
                this.submitLogin();
              }
              }} placeholder="Password"/>
          </div>
          <Link to="/forgot-password">Forgot Password?</Link>
          <div className="signInContainer-buttons">
            <div className="careButton" disabled="" id="signIn" value="Go" onClick={(event) => {
                event.preventDefault();
                this.submitLogin();
              }}>Sign In</div>
          </div>
        </div>
      </div>
    );
  }
};

export default Login;

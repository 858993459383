import React, { Component } from 'react';
import Swal from 'sweetalert2';
import axios from '../api';

const req = {
  color: 'red'
}

const errorStyle = {
  fontSize: '10px',
  color: 'red',
  display: 'block'
};

const userFields = ["name","phone"];

class Profile extends Component {
  constructor(props) {
    super();
    this.state = {
      profile: JSON.parse(localStorage.getItem('profile')),
      payload:JSON.parse(localStorage.getItem('profile')),
      error: {}
    }
    this.updateProfile = this.updateProfile.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.validateData = this.validateData.bind(this);
  
  }

  updateProfile(e) {
    e.preventDefault();
    if(this.validateData()) {
      axios.put("/benefit/profile", this.state.payload).then(res => {
        Swal.fire(
          'Success',
          'Profile updated successfully',
          'success'
        );
        // localStorage.setItem('profile', JSON.stringify(res.data.user));
      }, err => {
        this.setState({
          error: 'Invalid Credentials'
        });
      });
    }
  }

  validateData() {
    let proceed = true;
    let error = {};
    userFields.forEach(field => {
      if(!this.state.payload[field] || this.state.payload[field] === "") {
        error[field] = `${field} is required`;
        proceed = false;
      }
    });
    this.setState({
      error: error
    });
    return proceed;
  }

  getErrorMessage(field) {
    return this.state.error[field]?<span style={errorStyle}>{this.state.error[field]}</span>:'';
  }

  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-4 signInContainer">
          <h4 className="text-center" style={{marginBottom:'20px'}}>Update Profile</h4>
          <div className="form-group">
            <label>Full Name <span style={req}>*</span></label>
            <input type="text" className="form-control" value={this.state.payload.name} onChange={(event) => {
              this.setState(prevState => ({
                payload: {
                  ...prevState.payload,
                  name: event.target.value
                }
              }));
            }} placeholder="Enter name"/>
            {this.getErrorMessage('name')}
          </div>
          <div className="form-group">
            <label>Email Address</label>
            <h6>{this.state.profile.email}</h6>
          </div>
          <div className="form-group">
            <label>Mobile Number <span style={req}>*</span></label>
            <input type="number" className="form-control" value={this.state.payload.phone} onChange={(event) => {
              this.setState(prevState => ({
                payload: {
                  ...prevState.payload,
                  phone: event.target.value
                }
              }));
            }} placeholder="Mobile Number"/>
            {this.getErrorMessage('phone')}
          </div>
          <div className="signInContainer-buttons">
            <div className="careButton" disabled="" id="signIn" value="Go" onClick={this.updateProfile}>Update</div>
          </div>
        </div>
      </div>
    );
  }
};

export default Profile;
